<!-- 购物车页面 -->
<template>
  <div class="pageF6Color">
    <headgobackVue :iCustomizdde="true">
      <template #default>
        <span>
          {{ $fanyi("购物车") }}
        </span>
        <div class="headerButtonBox">
          <button
            v-if="userOperationMode == 'propose'"
            @click="userOperationMode = 'delete'"
          >
            {{ $fanyi("编辑") }}
          </button>
          <button class="redFont" v-else @click="userOperationMode = 'propose'">
            {{ $fanyi("完成") }}
          </button>
        </div>
      </template>
    </headgobackVue>
    <div class="cartCon" v-if="lists.length">
      <div class="shopsBox" v-for="(item, index) in lists" :key="index">
        <h2>
          <input
            type="checkbox"
            name=""
            id=""
            v-model="item.checked"
            @change="shopAll(index)"
          />

          <img :src="require('@/assets/icon/dianpu.png')" alt="" />
          <span
            @click="
              $fun.routerToPage(
                `/shopGoods?shopId=${item.shop_id}&shopType=${item.shop_type}&shopName=${item.shop_name}`
              )
            "
            >{{ item.shop_name }}</span
          >
        </h2>
        <div
          class="goodsBox"
          v-for="(listItem, listIndex) in item.goods"
          :key="listIndex"
        >
          <input
            type="checkbox"
            v-model="listItem.checked"
            @change="oddChoice(index)"
          />
          <div class="productPurchaseInfo">
            <div class="productInfo">
              <van-image
                class="productImage"
                @click="$lookImg(listItem.pic)"
                :src="listItem.pic"
                lazy-load
              >
                <template v-slot:loading>
                  <van-loading type="spinner" size="20" />
                </template>
              </van-image>
              <div>
                <h3
                  @click="
                    $fun.toCommodityDetails(
                      listItem.goods_id,
                      listItem.from_platform
                    )
                  "
                >
                  <span
                    class="icon1688"
                    v-if="listItem.from_platform == '1688'"
                  >
                    <img :src="require('@/assets/icon/icon1688.png')" alt="" />
                  </span>

                  {{ listItem.goods_title }}
                </h3>
                <div
                  class="productAttribute"
                  @click="
                    getProductSpecificationData({
                      item: listItem,
                      omethods: () => {
                        openDetailSheet(listItem);
                      },
                    })
                  "
                >
                  <p>
                    <span
                      v-for="(detailItem, detailIndex) in listItem.detail"
                      :key="detailIndex"
                    >
                      {{ detailItem.value }};
                    </span>
                  </p>

                  <img
                    class="arrow-down"
                    src="../../assets/icon/arrow-downskdjfh.png"
                    alt=""
                  />
                </div>
                <div class="quantityAndAmount">
                  <div class="amount">
                    <b>
                      {{
                        $fun.RMBNumSegmentation(
                          Number(
                            listItem.univalence
                              ? listItem.univalence
                              : Number(listItem.price)
                          )
                        )
                      }}元</b
                    >({{
                      $fun.JPYNumSegmentation(
                        $fun.roundNumber(
                          (listItem.univalence
                            ? listItem.univalence
                            : Number(listItem.price)) *
                            $store.state.exchangeRate
                        )
                      )
                    }}円)
                  </div>
                  <van-stepper
                    integer
                    v-model.number="listItem.num"
                    @change="
                      getProductSpecificationData({
                        item: listItem,
                        omethods: () => {
                          calculateSingleProductPrice(listItem);
                          editSingleProductAttribute(listItem);
                        },
                      })
                    "
                  />
                </div>
              </div>
            </div>
            <div
              class="startingBatch"
              v-if="listItem.price_ranges && listItem.price_ranges.length"
            >
              {{ $fanyi("最低批次") }}>={{
                listItem.price_ranges[0].startQuantity
              }}
            </div>
            <textarea
              class="customerNote"
              v-model="listItem.client_remark"
              :placeholder="$fanyi('请输入备注')"
              v-autoHeightForLineBreak
              @change="editSingleProductAttribute(listItem)"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="technicalSupport">
        {{ $fanyi("由1688提供部分技术服务⽀持") }}
      </div>
    </div>
    <div class="noData" v-else-if="!loading">
      <div>
        <img :src="require('@/assets/icon/crj.png')" alt="" />
        <span>{{ $fanyi("暂无历史记录") }}</span>
        <button @click="$fun.routerToPage('/')">{{ $fanyi("去购物") }}</button>
      </div>
    </div>
    <div class="cartBottom">
      <div class="dianzi"></div>
      <!-- 提出订单模式操作栏 -->
      <div class="con" v-if="userOperationMode == 'propose'">
        <input
          type="checkbox"
          name=""
          id=""
          v-model="checked"
          @change="allChoice"
        />
        <span
          class="quanXuan"
          @click="
            checked = !checked;
            allChoice();
          "
          >{{ $fanyi("全选") }}</span
        >
        <div class="numBox">
          <span class="fanshu">{{ $fanyi("sku") }}：{{ goodsTotal }}</span>
          <div class="toPri">
            <span>{{ $fanyi("总计") }}：</span>
            <span class="priceFont">
              {{ $fun.RMBNumSegmentation($fun.ceil(totalPrice)) }} 元
            </span>
            <span class="priceFont">
              ({{
                $fun.JPYNumSegmentation(
                  $fun.roundNumber(totalPrice * $store.state.exchangeRate)
                )
              }}円)
            </span>
          </div>
        </div>

        <button @click="jieSuan">{{ $fanyi("去订货") }}</button>
      </div>
      <div class="con" v-else>
        <input
          type="checkbox"
          name=""
          id=""
          v-model="checked"
          @change="allChoice"
        />
        <span
          class="quanXuan"
          @click="
            checked = !checked;
            allChoice();
          "
          >{{ $fanyi("全选") }}</span
        >
        <button @click="delCart()">
          {{ $fanyi("删除") }}
        </button>
      </div>
    </div>
    <productAttributeSelectionVue ref="productAttributeSelectionVueRef" />
    <footBarVue />
    <myDialogVue ref="myDialog" />
  </div>
</template>
<script setup>
import { getCurrentInstance, ref, onMounted } from "vue";
import myDialogVue from "@/components/Dialog";
import headgobackVue from "@/components/headGoBack";
import footBarVue from "@/components/footBar";
import productAttributeSelectionVue from "./compontnts/productAttributeSelection.vue";
const { proxy } = getCurrentInstance();

//------------------------ data -----------------------------------------
const userOperationMode = ref("propose"); //操作栏模式 propose为提出订单模式,delete为删除模式
const checked = ref(false); //全选
const goodsTotal = ref(0); //选中的商品种类
const isTotal = ref(0); // 选中的商品所有数量
const shopTotal = ref(0); // 选中的店铺
const totalPrice = ref(0); // 选中的商品总价
const lists = ref([]); // 数据
const idArr = ref([]); // 选中的商品ID
const loading = ref(true);
// //------------------------ pageLoad -------------------------------------
onMounted(() => {
  getCartList();
});
// //------------------------ methods --------------------------------------

// 打开选择商品属性弹窗
const openDetailSheet = (listItem) => {
  // listItem-单个商品所有数据
  proxy.$refs.productAttributeSelectionVueRef.open(listItem, () => {
    priceRangeMatching(listItem);
    editSingleProductAttribute(listItem);
  });
};
// 匹配单个商品的价格区间
const priceRangeMatching = (listItem) => {
  // listItem-单个商品所有数据
  // 设置默认的价格区间
  listItem.priceRange = (
    listItem.goodsInventory.find((InventoryItem) => {
      // InventoryItem-单个商品里的单条sku对象
      // 拼接出匹配sku的字符串
      let searchstr = listItem.detail.map((obj) => obj.value).join("㊖㊎");
      return InventoryItem.keyC == searchstr || InventoryItem.keyT == searchstr;
    }) || {}
  ).valueC;
};
// 递归查找数组里符合条件的对象(只会寻找key是goods的子数组)(传参:数组,要匹配的key名字,要匹配的value)
const findAllObjectsWithPropertyName = (array, propertyName, propertyValue) => {
  return array.flatMap((obj) => {
    if (obj[propertyName] === propertyValue) {
      return obj;
    } else if (obj.goods) {
      return findAllObjectsWithPropertyName(
        obj.goods,
        propertyName,
        propertyValue
      );
    } else {
      return [];
    }
  });
};
// 获取单个商品规格数据并且执行预存的操作 data传递{item,omethods}
const getProductSpecificationData = (odata) => {
  let execute = () => {
    if (odata.omethods) {
      odata.omethods();
    }
  };
  if (!!odata.item.goodsInventory && !!odata.item.specification) {
    execute();
  }
  if (!odata.item.goodsInventory || !odata.item.specification) {
    proxy.$api
      .getGoodsAttribute({
        shop_type: odata.item.from_platform,
        goods_id: odata.item.goods_id,
      })
      .then((GoodsAttribute) => {
        GoodsAttribute.data.forEach((item, index) => {
          // odata.item---单个商品所有数据
          odata.item.goodsInventory = JSON.parse(
            JSON.stringify(item.goodsInventory)
          );
          odata.item.specification = JSON.parse(
            JSON.stringify(item.specification)
          );
          // 为specification(商品属性列表)设置chiose(用户选中的属性)以备用
          odata.item.detail.forEach((detailItem, detailIndex) => {
            odata.item.specification[detailIndex].chiose = (
              odata.item.specification[detailIndex] || {}
            ).valueC.findIndex((item) => {
              return item.name == detailItem.value;
            });
          });
          // 设置默认的价格区间
          odata.item.priceRange = (
            odata.item.goodsInventory.find((InventoryItem) => {
              // InventoryItem-单个商品里的单条sku对象
              // 拼接出匹配sku的字符串
              let searchstr = odata.item.detail
                .map((obj) => obj.value)
                .join("㊖㊎");
              return (
                InventoryItem.keyC == searchstr ||
                InventoryItem.keyT == searchstr
              );
            }) || {}
          ).valueC;
          // calculateSingleProductPrice(odata.item);
          // 处理好数据之后如果有需要执行的事件就执行事件
          execute();
        });
      });
  }
};
// 获取数据
const getCartList = () => {
  proxy.$api.goodsCartList({ only_list: "yes" }).then((res) => {
    loading.value = false;
    if (res.code != 0) {
      lists.value = [];
      return false;
    }
    lists.value = res.data;
    //   获取所有商品的id和type
    let arr = [];
    res.data.forEach((item) => {
      item.goods.forEach((goodsItem) => {
        if (typeof goodsItem.detail === "string") {
          goodsItem.detail = JSON.parse(goodsItem.detail);
        }
      });
    });
    tidyData();
  });
};
// 整理数据
const tidyData = () => {
  lists.value.forEach((item) => {
    item.type = item.goods[0].type; // 店铺类别
    item.goods.forEach((item1) => {
      // 商品总数
      item1.checked = false; // 商品选择
      item1.univalence = item1.price; // 显示单价
      item1.total = 0;
      // item1.price_range = JSON.parse(item1.price); // 字符串转换组数
    });
  });
  // subtotal();
};
// 编辑单个商品属性
const editSingleProductAttribute = (listItem) => {
  // 计算金钱
  // subtotal();
  commodityTotal();
  //   将修改提交给后台
  let datas = JSON.parse(JSON.stringify(listItem));
  datas.detail = JSON.stringify(datas.detail);
  if (datas.univalence) {
    datas.price = datas.univalence;
  }
  proxy.$api.goodsCartEdit(datas).then((res) => {
    ////console.log('事件名',res)
    if (res.code != 0) return;
    //接下来的操作
  });
};
// 判断单个商品是用哪个价格 并计算总价
const calculateSingleProductPrice = (singleProduct) => {
  singleProduct.univalence = 0;
  for (let i = singleProduct.priceRange.length - 1; i >= 0; i--) {
    // priceItem-单个商品里的单条价格数据
    const priceItem = singleProduct.priceRange[i];
    if (priceItem.startQuantity <= singleProduct.num) {
      singleProduct.univalence = priceItem.price;
      break;
    }
  }
  singleProduct.total = proxy.$fun.ceil(
    singleProduct.num *
      (!!singleProduct.univalence
        ? singleProduct.univalence
        : singleProduct.price)
  );
};

// 判断每一番商品使用哪个价格 并计算单个商品总价
const subtotal = () => {
  lists.value.forEach((item) => {
    item.goods.forEach((listItem) => {
      listItem.total = proxy.$fun.ceil(
        listItem.num *
          (!!listItem.univalence ? listItem.univalence : listItem.price)
      );
    });
  });
};

// 单选
const oddChoice = (i) => {
  let flag = lists.value[i].goods.every((item) => item.checked == true);
  if (flag) {
    lists.value[i].checked = true;
  } else {
    lists.value[i].checked = false;
  }
  isCheckedAll();
  commodityTotal();

  //   flag ? (lists.value[i].checked = true) : (lists.value[i].checked = false);
};
// 店铺全选
const shopAll = (i) => {
  if (lists.value[i].checked) {
    lists.value[i].goods.forEach((item) => {
      item.checked = true;
    });
  } else {
    lists.value[i].goods.forEach((item) => {
      item.checked = false;
    });
  }

  isCheckedAll();
  commodityTotal();
};
// 全选
const allChoice = () => {
  if (checked.value) {
    lists.value.forEach((item, index) => {
      item.checked = true;
      shopAll(index);
    });
  } else {
    lists.value.forEach((item, index) => {
      item.checked = false;
      shopAll(index);
    });
  }
  commodityTotal();
};
// 是否全选中
const isCheckedAll = () => {
  var flag = lists.value.every((item, index) => item.checked == true);
  if (flag == true) {
    checked.value = true;
  } else {
    checked.value = false;
  }
};
// 统计数据 选中商品数量总数、总价
const commodityTotal = () => {
  totalPrice.value = 0; //选中的商品总价
  isTotal.value = 0; //选中的商品所有数量
  shopTotal.value = 0;
  goodsTotal.value = 0;
  idArr.value = [];
  subtotal(); //计算每个商品的总价
  lists.value.forEach((item, i) => {
    if (item.goods.some((items) => items.checked)) {
      shopTotal.value += 1;
    }
    item.goods.forEach((listItem, j) => {
      if (listItem.checked) {
        console.log(listItem.total);
        totalPrice.value += listItem.total - 0;
        isTotal.value += listItem.num;
        goodsTotal.value += 1;
        idArr.value.push(listItem.id);
      }
    });
  });
  //   //(totalPrice.value, isTotal.value, idArr.value);
};
// 删除
const delCart = (id, shopIndex, goodsIndex) => {
  let datas = {
    ids: "",
  };
  let msg = proxy.$fanyi("该商品将移出购物车");
  // 单个商品删除
  if (id) {
    datas.ids = id;
  } else {
    // 多个商品删除
    if (idArr.value == false)
      return proxy.$message.error(proxy.$fanyi("未选择操作的商品"));
    datas.ids = idArr.value.join(",");
    msg = proxy.$fanyi("该商品将移出购物车");
  }
  proxy.$refs.myDialog.open({
    text: msg,
    successBtn: proxy.$fanyi("删除"),
    isprompt: true,
    cancelBtn: proxy.$fanyi("取消"),
    title: proxy.$fanyi("提醒"),
    successMethods: () => {
      // on confirm
      // 确认按钮事件
      proxy.$api.goodsCartDelete(datas).then((res) => {
        if (res.code != 0) return;
        proxy.$message.success(proxy.$fanyi("移除成功"));
        // getCartList();
        // tidyData();
        // for (let i = 0; i < lists.value.length; i++) {}
        if (id) {
          lists.value[shopIndex].goods.splice(goodsIndex, 1);
          if (lists.value[shopIndex].goods == 0) {
            lists.value.splice(shopIndex, 1);
          }
        } else {
          // 将购物车选中的选项删除
          for (let i = 0; i < lists.value.length; i++) {
            let shopItem = lists.value[i];
            for (let j = 0; j < lists.value[i].goods.length; j++) {
              let goodsItem = lists.value[i].goods[j];
              if (idArr.value.indexOf(goodsItem.id) != -1) {
                shopItem.goods.splice(j, 1);
                j--;
              }
            }
            if (shopItem.goods.length == 0) {
              lists.value.splice(i, 1);
              i--;
            }
          }
        }
        // 获取总价
        commodityTotal();
        // 重新计算购物车商品数量
        proxy.$store.dispatch("goodsToCartNum");
        // }
      });
    },
  });
};

// 结算
const jieSuan = () => {
  if (idArr.value.length > 100) {
    return proxy.$message.warning(proxy.$fanyi("一个订单最多添加100个商品"));
  }
  if (!idArr.value.length) {
    return proxy.$message.warning(proxy.$fanyi("请至少选择一项"));
  }
  let id = idArr.value.join(",");
  proxy.$fun.routerToPage("/OrderDetails?id=" + id + "&type=temporary");
};
defineExpose({ calculateSingleProductPrice });
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.redFont {
  color: #b4272b !important;
}
input[type="checkbox"] {
  width: 30px;
  flex: 0 0 30px;
  height: 30px;
  border: 1px solid #dfdfdf;
  border-radius: 6px;
}
.headerButtonBox {
  button {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    padding: 0 35px;
    background-color: transparent;
    font-size: 24px !important;
    font-weight: 400 !important;
    color: #000000;
  }
  button.redFont {
    font-weight: 600 !important;
  }
}

.cartCon {
  padding: 20px 30px;
  .shopsBox {
    padding: 30px 30px 5px;
    background: #ffffff;
    border-radius: 6px;
    margin-bottom: 15px;
    h2 {
      display: flex;
      align-items: center;
      margin-bottom: 25px;

      input[type="checkbox"] {
        margin-right: 13px;
      }
      img {
        width: 32px;
        height: 30px;
        margin-right: 7px;
      }
      span {
        font-size: 28px;
        line-height: 1;
      }
    }
    .goodsBox {
      display: flex;
      margin-bottom: 24px;
      input[type="checkbox"] {
        margin-top: 6px;
        margin-right: 13px;
      }
      .productPurchaseInfo {
        .productInfo {
          display: flex;
          margin-bottom: 10px;
          .productImage {
            flex: 0 0 180px;
            height: 180px;
            margin-right: 24px;
            border-radius: 6px;
          }
          h3 {
            margin-top: -4px;
            font-size: 28px;
            line-height: 32px;
            margin-bottom: 15px;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            .icon1688 {
              width: 76px;
              height: 30px;
              background: #ff4000;
              border-radius: 2px;
              display: inline-flex;
              justify-content: center;
              align-items: center;
              margin-right: 5px;
              img {
                width: 52px;
              }
            }
          }

          .productAttribute {
            width: 350px;
            height: 42px;
            background: #ffffff;
            border: 1px solid #dfdfdf;
            border-radius: 6px;
            display: flex;
            align-items: center;
            padding: 0 12px;
            margin-bottom: 18px;
            p {
              line-height: 42px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-size: 24px;

              span {
                font-size: 24px;
              }
            }
            .arrow-down {
              width: 16px;
              height: 9.5px;
              line-height: 1;
              font-weight: 700;
              color: #333333;
              margin-left: auto;
            }
          }
          .quantityAndAmount {
            width: 370px;
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            .amount {
              font-size: 24px;
              color: #b4272b;
              b {
                font-size: 28px;
                font-weight: 600;
              }
            }
            .van-stepper,
            :deep(.van-stepper) {
              width: 146px;
              button {
                flex: 0 0 40px;
                height: 40px;
              }
              input {
                flex: 0 0 60px;
                width: 60px;
                height: 40px;
                margin: 0 3px;
              }
            }
          }
        }
        .startingBatch {
          text-align: right;
          color: #999999;
          font-size: 20px;
          margin-bottom: 18px;
        }
        .customerNote {
          width: 100%;
          height: 42px;
          background: #ffffff;
          border: 1px solid #dfdfdf;
          border-radius: 6px;
          font-size: 24px;
          line-height: 40px;
          padding: 0px 12px;
        }
      }
    }
  }
  .technicalSupport {
    padding: 15px 0 10px;
    font-size: 20px;
    color: #999999;
    line-height: 1;
    text-align: center;
  }
}
.cartBottom {
  .dianzi {
    height: 100px;
  }
  .con {
    background: #ffffff;
    border: #dfdfdf solid 1px;
    border-left: none;
    border-right: none;
    position: fixed;
    bottom: 119px;
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    padding: 0 30px;
    span {
      display: inline-block;
      font-size: 24px;
      line-height: 1;
    }
    input[type="checkbox"] {
      margin: 0;
      margin-right: 6px;
    }
    .quanXuan {
      margin-right: 30px;
    }
    .numBox {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      * {
        font-size: 24px;
      }
      .fanshu {
        width: 120px;
        margin-bottom: 11px;
      }
      .toPri {
        .priceFont {
          font-weight: 600;
          color: #b4272b;
        }
      }
    }

    button {
      margin-left: auto;
      width: 150px;
      height: 70px;
      background: #b4272b;
      border-radius: 6px;
      font-size: 28px;
      line-height: 1;
      color: #ffffff;
    }
  }
}
.noData {
  padding-top: 177px;
  div {
    display: flex;
    align-items: center;
    flex-direction: column;
    img {
      width: 294px;
      height: 270px;
      margin-bottom: 35px;
    }
    span {
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 40px;
      color: rgba(153, 153, 153, 1);
    }
    button {
      width: 240px;
      height: 90px;
      background: #b4272b;
      border-radius: 6px;
      font-size: 28px;
      color: #ffffff;
    }
  }
}
</style>
